&.mode-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  image-editor {
    position: fixed;
    left: 5%;
    top: 5%;
    bottom: 5%;
    right: 5%;
    margin: auto;
    max-width: 90%;
    max-height: 90%;
    z-index: 10;
    box-shadow: inset 0 -2px 5px rgba(61, 61, 61, 0.5), 0 6px 44px rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    overflow: hidden;
  }

  .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }
}